import { ReactComponent as IconPhone } from '../icons/phone-portrait-outline.svg';
import { ReactComponent as IconTablet } from '../icons/tablet-portrait-outline.svg';
import { ReactComponent as IconLaptop } from '../icons/laptop-outline.svg';

export const useDevicesSupported = (doc: any) => {
    if(doc.data.mobile || doc.data.tablet || doc.data.desktop) {
        return (
            <div className='storyDevices'>
                {doc.data.mobile && (
                    <div className='device' title='mobiel'>
                        <IconPhone width={'35px'} />
                    </div>
                )}
                {doc.data.tablet && (
                    <div className='device' title='tablet'>
                        <IconTablet width={'35px'} />
                    </div>
                )}
                {doc.data.desktop && (
                    <div className='device' title='desktop'>
                        <IconLaptop width={'35px'} />
                    </div>
                )}
            </div>
        )
    } 
    return null
}