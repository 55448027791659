import { useEffect, useRef, useState } from "react"
import { Tier } from "./tier"
import { PrismicRichText } from "@prismicio/react";

export const Tiers = ({ tiers, onSelected }) => {
    const [selected, setSelected] = useState(null);

    const tiersDescriptionElement = useRef<any>(null)

    const selectedTitle = selected && tiers.find(tier => tier.title[0].text.toLowerCase().replace(' ', '_') === selected).title[0].text.toLowerCase()
    console.log('selectedTitle', selectedTitle)
    const selectedDescription = selected && tiers.find(tier => tier.title[0].text.toLowerCase().replace(' ', '_') === selected).description

    const scrollToDescription = () => {
        const { current } = tiersDescriptionElement
        current && document.querySelector('.containerStoryOpened')?.scrollTo({top: current.offsetTop + (current.offsetHeight / 2), behavior: 'smooth'})
    }

    useEffect(() => {
        if(!selected) {
            const firstSelectedTier = tiers[2].title[0].text
            
            setSelected(firstSelectedTier.toLowerCase().replace(' ', '_'))
            onSelected(firstSelectedTier)
        }
    }, [selected])

    return (
        <div style={{ marginTop: '3rem'}}>
            <h2 id={"ScrollToOptions"} style={{ fontStyle: 'italic' }}>Kies de juiste instap voor jouw idee hieronder</h2>
            <ul className="tierscontainer">
                {tiers.map((tier, index) => {
                    const tierSelectable = tier.title[0].text.toLowerCase().replace(' ', '_')
                    return (
                        <Tier
                            key={'Tier_' + index}
                            isSelected={selected === tierSelectable}
                            onSelected={() => {
                                setSelected(tierSelectable)
                                onSelected(tier.title[0].text)
                                scrollToDescription()
                            }}
                            tier={tier}
                        />
                    )
                })}
            </ul>

            {selectedDescription && (
                <div ref={tiersDescriptionElement}>
                    <h2>
                        Pssst hier lees je meer over <br/><span style={{ fontStyle: 'italic' }}>{selectedTitle}</span>
                    </h2>
                    <PrismicRichText field={selectedDescription} />
                    <h2 style={{ marginTop: '3rem', fontStyle: 'italic' }}>
                        Wil je meer weten?
                    </h2>
                    <p>
                        Laat hieronder je gegevens achter en ontvang <span style={{textDecoration: 'underline'}}>een gratis uur brainstormen met ons</span> en wij nemen binnen een werkweek contact op met jou!
                    </p>
                </div>
            )}
        </div>
    )
}