type PhoneMockupProps = {
    imageUrl?: string;
    width?: string; 
}

export const PhoneMockup = ({ imageUrl, width }: PhoneMockupProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 500 1053.24">
        <defs>
            <clipPath id="clippathmobile">
                <rect fill="none" x="15.7" y="14.61" width="468.59" height="1024.01" rx="68.27" ry="68.27" />
            </clipPath>
        </defs>
        <rect fill="#292929" width="500" height="1053.24" rx="80.88" ry="80.88" />
        <g clipPath="url(#clippathmobile)">
            {imageUrl ? (
                <image href={imageUrl && imageUrl} x="15" y="15" width="470" height="1025" />
            ) : (
                <rect fill={imageUrl ? '' : 'white'} height="100%" width="100%" />
            )}
        </g>
        <path fill="#292929" d="M127.79,3.03l2.1,20.27c1.37,13.26,14.04,23.41,29.22,23.41h181.78c15.17,0,27.84-10.15,29.22-23.41l2.1-20.27H127.79Z" />
    </svg>
)