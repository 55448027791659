import { isMobile } from 'react-device-detect';
import { motion } from "framer-motion";
import { PrismicDocument } from '@prismicio/types/dist';
import { Filler } from '../stories/filler';
import { Link } from 'react-router-dom';

import { ReactComponent as IconClose } from '../../icons/close-outline.svg';
import { PrismicRichText, usePrismicDocumentByID } from '@prismicio/react';
import { Footer } from 'components/footer';
import { Tiers } from 'components/tiers';
import { Contact, ContactLabs } from 'components/contact';
import { useState } from 'react';

type PropositionProps = {
    docs: any;
    id: string;
    onCloseStory: () => void;
}

export const Proposition = ({ docs, id, onCloseStory }: PropositionProps) => {
    const [selectedTier, setSelectedTier] = useState(undefined)

    const doc = docs.find((doc: PrismicDocument) => doc.slugs[0] === id)

    const { contact_employee, content, cover_image, tiers, title } = doc.data;

    const callToActionList = content.filter(contentItem => contentItem.text.includes('##')).map(cta => {
        console.log('cta', cta, cta.text.split('##'))
        const text = cta.text.split('##')[1]
        return {
            type: "heading2",
            text: text,
            spans: [
                {
                    start: 0,
                    end: text.length,
                    type: "hyperlink",
                    data: {
                        link_type: "Web",
                        url: "#ScrollToOptions"
                    }
                }
            ]
        }
    })
    const contentList = content.filter(contentItem => contentItem.text.indexOf('##') === -1)

    const [employeeData] = usePrismicDocumentByID(contact_employee.id)

    contentList.splice(2, 0, callToActionList[0])

    return (

        <motion.div layoutId={`story_${id}`} className={'containerStoryOpened'} data-proposition-opened={true}>
            <motion.div
                initial={{ x: '50%', y: '50%' }}
                layoutId={`story_coverImage_${id}`} className={'storyCoverImage propositionCoverImage'} style={{ backgroundImage: isMobile ? `url('${doc.data.cover_image.mobile.url}')` : '' }}>
                <div className='storyCoverGradientOverlay' ></div>
                {!isMobile ? (
                    <>
                        <video preload='true' autoPlay muted loop style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}>
                            <source src={doc.data.cover_video.url} type='video/mp4' />
                        </video>
                        <img src='/images/labs/transparent_black_square@4x.png' style={{ alignSelf: 'center', height: '50vh', width: 'auto', position: 'fixed' }} />
                    </>
                ) : (
                    <img src='/images/labs/transparent_black_square@4x.png' style={{ alignSelf: 'center', height: '50vh', width: 'auto', position: 'fixed', bottom: '15vh' }} />
                )}
            </motion.div>

            <Filler />

            <motion.div
                initial={isMobile ? { x: '0%', y: '0%' } : { x: '50%', y: '50%' }}
                layoutId={`story_content_container_${id}`} className='storyContentContainer propositionContentContainer'
            >
                <motion.div
                    layoutId={`story_header_${id}`} className={'storyHeader'}>

                    <PrismicRichText field={title} />
                </motion.div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: '20%' }}
                animate={{ opacity: 1, y: '0%' }}
                exit={{ opacity: 0, y: '20%' }}
                className={'storyCard storyContent propositionContent'}
            >
                <>
                    <Link to={'/'} onClick={() => onCloseStory()} className={'close'} data-has-icon={'true'} data-color-inverted={'true'}>
                        <IconClose width={'26px'} />
                    </Link>

                    <PrismicRichText
                        field={contentList}
                        components={{
                            image: (props) => {
                                return (
                                    <figure className="block-img">
                                        <img src={props.node.url} alt={props.node.alt || 'picture'} />
                                        {props.node.alt && (
                                            <figcaption>{props.node.alt}</figcaption>
                                        )}
                                    </figure>
                                )
                            }
                        }}
                    />

                    {tiers && <Tiers tiers={tiers} onSelected={(value) => setSelectedTier(value)} />}

                </>
            </motion.div>

            {employeeData && (
                <motion.div className={'storyCard storyContact'}>
                    <ContactLabs employee={employeeData} storyInfo={doc} traject={selectedTier} />
                </motion.div>
            )}

            <Footer />
        </motion.div>
    )
}