import React from 'react';
import animateScrollTo from 'animated-scroll-to';
import { isMobile } from 'react-device-detect';

import { getRandomInt } from '../../helpers';

import { Logo } from '../';


import './header.scss';

import { ReactComponent as IconArrowDown } from '../../icons/arrow-down-outline.svg';

export const Header = () => (
    <header className={'headerContainer'}>
        <div className={'forground'}>
            <Logo inverted width={'30rem'} />
            {/* <h1 className={'payoff'}>Helping ideas flourish</h1> */}
            <h1 className={'payoff'}>Van idee tot app of platform</h1>
            {/* <h1>Creative Solutions oriented Development</h1> */}
            <h2 className={'stories'}>Het verhaal van <strong>Pokka</strong></h2>
            <div onClick={() => {
                animateScrollTo(document.querySelector('.containerList')!)
                    .then(hasScrolledToPosition => {
                        if (hasScrolledToPosition) {
                            // reached destination
                        } else {
                            // interupted by user
                        }
                    })
            }}>
                <IconArrowDown style={{ cursor: 'pointer', stroke: 'white', width: '1rem' }} />
            </div>
        </div>
        
        {!isMobile && (
            <video preload='true' autoPlay muted loop>
                <source src={`video${getRandomInt(3) + 1}.mp4`} type='video/mp4' />
            </video>
        )}
    </header>
)