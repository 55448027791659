import { PrismicRichText } from "@prismicio/react"

import './cleanContent.scss';
import { BackgroundBubblesContainer, Bubbles } from "components/bubbles";
import { Logo } from "components/logo";

export const CleanContent = ({
    data
}) => {
    const { content, title } = data.data

    return (
        <>
            <Logo width="75%" />
            <main className="containerCleanContent storyCard">
                <PrismicRichText field={title} />
                <PrismicRichText field={content} />
            </main>
            <BackgroundBubblesContainer>
                <Bubbles isLarge maximum={7} minimum={3} />
            </BackgroundBubblesContainer>
        </>
    )
}