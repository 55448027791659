type DesktopMockupProps = {
    imageUrl?: string;
    width?: string;
}

export const DesktopMockup = ({ imageUrl, width }: DesktopMockupProps) => (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 862.55 491.92">
        <defs>
            <clipPath id="clippathdesktop">
                <rect fill="none" x="90.03" y="9.59" width="683.23" height="437.59" />
            </clipPath>
        </defs>
        <g clipPath="url(#clippathdesktop)">
            {imageUrl ? (
                <image href={imageUrl && imageUrl} width="100%" />
                // <image width="3456" height="2234" transform="translate(89.34 7.51) scale(.2)" xlink:href="../../../../../../../../Desktop/Screenshot 2021-12-17 at 10.46.47.png"/>
            ) : (
                <rect fill={imageUrl ? '' : 'white'} height="100%" width="100%" />
            )}
        </g>
        <path fill="#b2b2b2" d="M762.49-.04H100.15c-11.87,0-21.49,9.62-21.49,21.49V470.63h3.07V18.37c0-.95,.07-1.88,.21-2.79,.04-.3,.1-.6,.16-.9,.06-.3,.13-.59,.2-.89,.08-.29,.16-.59,.25-.87,.76-2.45,2.02-4.67,3.66-6.56,.19-.22,.39-.44,.59-.65,.1-.11,.2-.21,.31-.32,.94-.93,1.97-1.77,3.09-2.49,.37-.24,.75-.47,1.15-.68,.39-.21,.79-.41,1.19-.59h0c.27-.12,.55-.24,.82-.35h0c2.08-.82,4.36-1.28,6.73-1.28H762.45c3.16,0,6.15,.81,8.75,2.22,.39,.21,.77,.44,1.15,.68,.49,.32,.97,.66,1.44,1.02h0c.23,.19,.46,.37,.68,.56h0c.33,.29,.66,.59,.97,.9,.11,.11,.21,.21,.31,.32,.2,.21,.4,.43,.59,.65,2.79,3.22,4.49,7.43,4.49,12.02V470.63h3.11V20.1c-.7-11.24-10.03-20.14-21.45-20.14Z" />
        <rect fill="#3b3b3b" x="81.73" y="455.54" width="699.09" height="15.09" />
        <path fill="#cbcbcb" d="M862.51,477.65v-5.87h-78.57v-1.15H78.66v1.15H0v5.87H0v7.27c0,3.87,3.14,7.01,7.01,7.01H855.56c3.86,0,7-3.13,7-7v-7.28h-.04Z" />
        <path d="M762.44,0H100.11C89.98,0,81.73,8.24,81.73,18.37V455.54H780.82V18.37C780.82,8.24,772.58,0,762.44,0Zm10.08,447.18H90.03V19.04c0-5.22,4.23-9.45,9.45-9.45H763.07c5.22,0,9.45,4.23,9.45,9.45V447.18Z" />
        <path d="M458.49,20.1h-54.47c-2.23,0-4.03-1.81-4.03-4.03v-4.47h0c0-1.1-.89-2-2-2h66.44c-1.08,.02-1.94,.92-1.91,2h0v4.47c0,2.23-1.81,4.03-4.03,4.03Z" />
    </svg>
)