import { PrismicRichText } from "@prismicio/react"
import { isMobile } from 'react-device-detect';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ReactComponent as IconArrowRight } from '../../icons/arrow-forward-outline.svg';
import { ProjectBubblesClipping, ProjectBubblesMobileClipping } from "components/shapes";
import { Bubbles } from "components/bubbles";

type PropositionProps = {
    docs: any;
    onShowStory: () => void;
    selectedId: string;
}

type ItemProps = {
    doc: any;
    isSelected: boolean;
    onShowStory: () => void;
}

const Item = ({ doc, onShowStory }: ItemProps) => {
    const id = doc.slugs[0]

    return (
        <li>
            <motion.div onClick={() => onShowStory()} layoutId={`story_${id}`} className={'containerStory'}>
                <motion.div initial={{ x: '0%', y: '0%' }} layoutId={`story_content_container_${id}`} className='storyContentContainer proposition'>
                    <motion.div
                        layoutId={`story_header_device_${id}`}
                        style={{
                            marginBottom: '-10rem',
                        }}
                    >
                        <img src="/images/labs/transparent_black_square@4x.png" />
                    </motion.div>
                    
                    <motion.div layoutId={`story_header_${id}`} className={'storyHeader'}>
                        <PrismicRichText field={doc.data.title} />
                        
                        <Link to={`/proposition/${doc.slugs[0]}`} data-color-inverted={'true'} data-has-icon={'true'} className={'readmore'}>
                            Lees meer
                            <IconArrowRight width={'20px'} />
                        </Link>
                    </motion.div>
                </motion.div>
                <div className='storyBubbleContainer'>
                    <ProjectBubblesClipping layoutId={`story_coverImage_${id}`} imageUrl={isMobile ? doc.data.cover_image.mobile.url : doc.data.cover_image.url} width={'100%'} />
                    <ProjectBubblesMobileClipping layoutId={`story_coverImage_${id}`} imageUrl={isMobile ? doc.data.cover_image.mobile.url : doc.data.cover_image.url} width={'100%'} />
                </div>

                <div className='storyBubblesContainer'>
                    <Bubbles minimum={3} maximum={4} />
                </div>
            </motion.div>
        </li>
    )
}

export const Propositions = ({ docs, onShowStory, selectedId }: PropositionProps) => {
    return (
        <ul className={'containerList'}>
            {docs && docs.map((doc: any) => (
                <Item key={doc.id} doc={doc} onShowStory={() => onShowStory()} isSelected={doc.slugs[0] === selectedId} />
            ))}
        </ul>
    )
}