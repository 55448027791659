import { Tag } from "./tag";

import './tags.scss';

type TagsProps = {
    items: any
}

type TagProps = {
    item: any
}

export const Tags = ({items}: TagsProps) => (
    <ul className={'tagsContainer'}>
        {items && items.map(({item}: TagProps, index) => (
            <Tag key={'tag_' + index} tag={item} />
        ))}
    </ul>
)