import React, { useEffect, useState } from 'react';

export const Filler = () => {
    const [topPos, setTopPos] = useState<number>(window.innerHeight / 100 * 75)

    useEffect(() => {
        const scrollHandler = () => {
            if(document.querySelector('.containerStoryOpened')!.scrollTop < window.innerHeight / 100 * 75 ){
                setTopPos(window.innerHeight / 100 * 75 - document.querySelector('.containerStoryOpened')!.scrollTop - 50)
            }
        }
        const scrollEventHandler = () => {
            if(document.querySelector('.containerStoryOpened')){
                document.querySelector('.containerStoryOpened')!.addEventListener('scroll', scrollHandler, false)
            }
        }
        return scrollEventHandler
    }, [document.querySelector('.containerStoryOpened')])
    
    return (
        <div
            style={{
                width: '100%',
                height: 'calc(100vh + 50px)',
                display: 'flex',
                background: 'white',
                position: 'fixed',
                transform: `translateY(${topPos}px)`,
                pointerEvents: 'none',
                zIndex: '-1',
            }}
        ></div>
    )
}