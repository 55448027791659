import { DesktopMockup, PhoneMockup, TabletMockup } from "components/shapes"

export const useDeviceShowcase = (doc: any) => {
    let url = ''

    if(doc.data.mobile_cover.url) {
        url = doc.data.mobile_cover.url

        return <PhoneMockup imageUrl={url} width={'70%'} />
    } else if(doc.data.tablet_cover.url ) {
        url = doc.data.tablet_cover.url

        return <TabletMockup imageUrl={url} width={'80%'} />
    } else if(doc.data.desktop_cover.url) {
        url = doc.data.desktop_cover.url

        return <DesktopMockup imageUrl={doc.data.desktop_cover.url} width={'90%'} />
    } else {
        return null
    }
}