import { usePrismicDocumentByID, usePrismicPreviewResolver } from '@prismicio/react'
import { Story } from 'components'
import { useNavigate } from 'react-router-dom'

function Preview() {
    const navigate = useNavigate()

    const { search } = window.location

    const searchParams = new URLSearchParams(search)
    const documentId = searchParams.get('documentId')!

    const [previewDoc] = usePrismicDocumentByID(documentId)
    console.log('previewDoc', previewDoc)

    // This performs a client-side redirect to the previewed document's URL
    
    return (
        previewDoc ? (
            // @ts-ignore
            <Story id={previewDoc.slugs[0]} docs={[previewDoc]} onCloseStory={() => navigate({to: window.location.origin})} key={"selectedStory"} preview />
        ) : <div></div>
    )
}

export default Preview;