import * as EmailJS from '@emailjs/browser';
import { PrismicText } from '@prismicio/react';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';

import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from '../';

import { ReactComponent as EmployeeBackground } from '../shapes/SVG/employee_title-background.svg';
import { ReactComponent as IconArrowRight } from '../../icons/arrow-forward-outline.svg';

import './contact.scss';

type ContactProps = {
    employee?: any;
    storyInfo?: any;
}

export const Contact = ({ employee, storyInfo }: ContactProps) => {
    const [captchaValidated, setCaptchaValidated] = useState(false)
    const [disabledForm, setDisabledForm] = useState(true)
    const [formFilled, setFormFilled] = useState({
        from_name: false,
        from_email: false,
        question: false,
        message: false,
    })
    const [hideConfetti, setHideConfetti] = useState(false)
    const [loading, setLoading] = useState(false)
    const [messageSend, setMessageSend] = useState(false)

    EmailJS.init(process.env.REACT_APP_EMAILJS_API_PUBLIC_KEY!)

    const sendMail = (event: SyntheticEvent | any) => {
        event.preventDefault()

        setLoading(true)

        let data = {
            to_name: 'Pokka bro',
            employee: `${employee.data.firstname[0].text} ${employee.data.lastname[0].text}`,
            story_title: storyInfo.data.display_title[0].text,
            story_url: window.location.href,
        }

        Array.from(event.target.elements).forEach((item: any) => {
            if (item.id) {
                //@ts-ignore
                data[item.id] = item.value
            }
        })

        console.log('data', data)

        EmailJS.send(process.env.REACT_APP_EMAILJS_SERVICE_ID_OUTLOOK!, process.env.REACT_APP_EMAILJS_TEMPLATE_ID!, data, process.env.REACT_APP_EMAILJS_API_PUBLIC_KEY)
            .then(response => {
                console.log('response', response)
                setLoading(false)
                if (response.status === 200) {
                    setMessageSend(true)
                }
            })
    }

    const captchaCheck = (event) => {
        console.log(event.target.value)
        let user_captcha_value = event.target.value;

        if (validateCaptcha(user_captcha_value) === true) {
            console.log('Captcha Matched');
            setCaptchaValidated(true)
        } else {
            console.log('Captcha Does Not Match');
        }
    };

    const onValidateForm = (event) => {
        const id = event.target.id
        const value = event.target.value
        console.log('value', value)
        setFormFilled(prevState => {
            return {
                ...prevState,
                [id]: value ? true : false
            }
        })
    }

    useEffect(() => {
        !captchaValidated && loadCaptchaEnginge(6)
    }, [captchaValidated])


    useEffect(() => {
        let notFilledForm = false
        for (const key in formFilled) {
            !formFilled[key] && (notFilledForm = true)
        }

        captchaValidated && setDisabledForm(notFilledForm)
    }, [formFilled, captchaValidated])

    useEffect(() => {
        if (messageSend) { 
            setTimeout(() => {setHideConfetti(true)}, 10000)
        }
    }, [messageSend])


    return (
        <div className={'container'}>

            <div className={'contactContainer'} style={{ backgroundImage: `url("${employee.data.photo.url}")` }}>

                <div className='employeeData'>
                    <h2>
                        <PrismicText field={employee.data.firstname} />
                        {' '}
                        <PrismicText field={employee.data.lastname} />
                    </h2>
                    <div>
                        {employee.data.position}
                    </div>
                    <div>
                        <a href={`tel:${employee.data.phonenumber[0].text}`}><PrismicText field={employee.data.phonenumber} /></a>
                    </div>
                </div>
                <EmployeeBackground />
            </div>
            {messageSend ? (
                <>
                    <div className={"formContainer"} style={{alignItems: 'center', justifyContent: 'center', minHeight: '40rem', textAlign: 'center'}}>
                        <span style={{fontSize: '6rem'}}>👍</span>
                        <br/>
                        <br/>
                        YEAH, je bericht is binnen!
                        <br/>
                        <br/>
                        Je hoort snel van ons!
                    </div>
                    {!hideConfetti && (
                        <ReactConfetti />
                    )}
                </>
            ) : (
                <form className={"formContainer"} onSubmit={(event) => sendMail(event)} style={{opacity: loading ? .5 : 1}}>
                    <div className='inputGroup'>
                        <input id={'from_name'} type={'text'} html-for={'labelname'} onBlur={onValidateForm} />
                        <label id={'labelname'}>Naam <span>*</span></label>
                    </div>
                    <div className='inputGroup'>
                        <input id={'from_email'} type={'email'} html-for={'labelemail'} onBlur={onValidateForm} />
                        <label id={'labelemail'}>Email <span>*</span></label>
                    </div>
                    <div className='inputGroup'>
                        <input id={'from_phone'} type={'number'} html-for={'labelphone'} />
                        <label id={'labelphone'}>Telefoon</label>
                    </div>
                    <div className='inputGroup'>
                        <select id={'question'} html-for={'labelquestion'} defaultValue={'1'} onChange={onValidateForm}>
                            <option disabled value={'true'} id={'1'}>Maak een keuze</option>
                            <option id={'2'}>(Dit) Verhaal gerelateerd</option>
                            <option id={'3'}>Diensten gerelateerd</option>
                            <option id={'4'}>Algemene dingen</option>
                        </select>
                        <label id='labelquestion'>Onderwerp <span>*</span></label>
                    </div>
                    <div className='inputGroup'>
                        <textarea id='message' rows={8} html-for='labelmessage' onBlur={onValidateForm}></textarea>
                        <label id='labelmessage'>Bericht <span>*</span></label>
                    </div>

                    {!captchaValidated ? (
                        <div className='inputGroup'>
                            <input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" onBlur={captchaCheck} />
                            <LoadCanvasTemplate />
                            <label>Captcha <span>*</span></label>
                        </div>
                    ) : (
                        <div className='inputGroup'>
                            Correct captcha!
                        </div>
                    )}
                    <div className='inputGroup'>
                        <button disabled={disabledForm} data-has-icon={'true'} type={'submit'}>
                            Verzend
                            <IconArrowRight width={'24px'} />
                        </button>
                    </div>
                </form>
            )}

        </div>
    )
}