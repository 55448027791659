import { PrismicImage, PrismicRichText } from "@prismicio/react";
import './tiers.scss';

export const Tier = ({ isSelected, onSelected, tier }) => {
    const { excerpt, icon, title } = tier;

    return (
        <li className='tierContainer' data-selected={isSelected} onClick={onSelected}>
            <PrismicRichText field={title} />
            <PrismicRichText field={excerpt} />
            <PrismicImage field={icon} style={{ 
                bottom: '5%',
                opacity: .05,
                position: 'absolute',
                right: '1rem',
                width: '60%',
                zIndex: -1,
            }} />
        </li>
    )
}