import { motion } from "framer-motion";
import { getRandomInt, getRandomIntBetween } from "helpers"

type BubbleProps = {
    isLarge?: boolean;
    maximum: number;
    minimum: number;
}

export const Bubbles = ({ isLarge, maximum, minimum }: BubbleProps): JSX.Element => {
    const bubbleAmmount = getRandomIntBetween(minimum, maximum)

    let bubbleStartingYPos = getRandomInt(4) + 1

    const bubbles = Array(bubbleAmmount).fill(null).map((curr, i) => {
        const ifStartingAndEnding = i === 0 || i === (bubbleAmmount - 1)
        const addedRandomYAmount = (getRandomInt(3) + 1)
        let yPos = bubbleStartingYPos + addedRandomYAmount
        let duration = getRandomIntBetween(6, 10)

        let xPosRange = ifStartingAndEnding ? getRandomIntBetween(-3, 3) : getRandomIntBetween(-13, 13)

        let bubbleSize = ifStartingAndEnding ? (getRandomIntBetween(3, 6) * 10) * 1.5 : (getRandomIntBetween(7, 10) * 10) * 1.5


        if (i !== 0) {
            bubbleStartingYPos += (addedRandomYAmount * 2.5)
        }

        if (isLarge) {
            bubbleSize = bubbleSize * 8.5
            xPosRange = xPosRange * 8
            yPos = yPos * 200
            duration = duration * 4
        }

        return {
            x_square: ifStartingAndEnding ? xPosRange : xPosRange + 5,
            y_square: i === 0 ? bubbleStartingYPos : yPos,
            duration: duration,
            rotate: getRandomIntBetween(30, 40),
            scale: getRandomIntBetween(900, 1100) / 1000,
            size: bubbleSize,
        }
    })

    return (
        <>
            {bubbles && bubbles.map((bubble, index) => (
                <motion.div
                    key={'bubble_' + index}
                    initial={{ x: bubble.x_square + 'vw' }}
                    animate={{ y: getRandomIntBetween(-1, 1) + 'vh', rotate: bubble.rotate }}
                    transition={{ ease: 'easeInOut', duration: isLarge ? getRandomIntBetween(15, 20) : getRandomIntBetween(6, 12), repeat: Infinity, repeatType: 'reverse' }}
                    style={{ filter: isLarge ? 'blur(5px)' : '', height: 10, width: 10 }}
                >
                    <motion.div
                        initial={{ x: getRandomIntBetween(-2.5, .5), y: getRandomIntBetween(-2.5, .5), scale: bubble.scale }}
                        animate={{ scale: bubble.scale }}
                        transition={{ ease: 'easeInOut', duration: bubble.duration, repeat: Infinity, repeatType: 'reverse' }}
                        style={{ backgroundColor: 'black', borderRadius: bubble.size, height: bubble.size, width: bubble.size, position: 'relative' }}
                    >
                    </motion.div>
                </motion.div>
            ))}
        </>
    )
}