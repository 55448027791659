import React from 'react';
import { Link } from 'react-router-dom';

import { isMobile } from 'react-device-detect';
import { motion } from "framer-motion";

import { PrismicRichText, usePrismicDocumentByID } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types/dist';

import { Contact, Footer, Tags } from '../';

import { useDeviceShowcase, useDevicesSupported } from 'helpers';

import { ReactComponent as IconClose } from '../../icons/close-outline.svg';

import './stories.scss';
import { Filler } from './filler';

type StoryProps = {
    docs: any;
    id: string;
    preview?: boolean;
    onCloseStory: () => void;
}

export const Story = ({docs, id, preview, onCloseStory}: StoryProps) => {
    const doc = docs.find( (doc: PrismicDocument) => doc.slugs[0] === id)

    const initialPosition = isMobile || preview ? {x: 0, y: 0} : { x: '50%', y: '50%'}

    const [employeeData] = usePrismicDocumentByID(doc.data.contact_employee.id)
    
    return (
        <motion.div layoutId={`story_${id}`} className={'containerStoryOpened'} data-story-opened={true}>
            <motion.div 
                initial={initialPosition}
                layoutId={`story_coverImage_${id}`} className={'storyCoverImage'} style={{backgroundImage: `url('${isMobile ? doc.data.cover_image.mobile.url : doc.data.cover_image.url}')`}}>
                    <div className='storyCoverGradientOverlay' ></div>
            </motion.div>

            <Filler />

            <motion.div
                initial={initialPosition}
                layoutId={`story_content_container_${id}`} className='storyContentContainer'
            >
                <motion.div
                    layoutId={`story_header_device_${id}`}
                >
                    {useDeviceShowcase(doc)}
                </motion.div>
                <motion.div 
                    layoutId={`story_header_${id}`} className={'storyHeader'}>

                    <PrismicRichText field={doc.data.display_title} />

                    <div style={{alignItems: 'center', display: 'flex', pointerEvents: 'initial'}}>
                        {doc.data.app_store_url.url && (
                            <a href={doc.data.app_store_url.url} target="_blank" rel="noreferrer">
                                <img src='/images/str_app_store.png' style={{height:'2.25rem', width: 'auto'}} alt="Apple Store Download" />
                            </a>
                        )}
                        {doc.data.play_store_url.url && (
                            <a href={doc.data.play_store_url.url} target="_blank" rel="noreferrer">
                                <img src='/images/str_google_play.png' style={{height:'3.3rem', width: 'auto'}} alt="Google Playstore Download" />
                            </a>
                        )}
                    </div>

                    {useDevicesSupported(doc)}
                </motion.div>
            </motion.div>
            <motion.div 
                initial={{ opacity: 0, y: '20%' }}
                animate={{ opacity: 1, y: '0%' }}
                exit={{ opacity: 0, y: '20%' }}
                className={'storyCard storyContent'}
            >
                <>
                    <Link to={'/'} onClick={()=>onCloseStory()} className={'close'} data-has-icon={'true'} data-color-inverted={'true'}>
                        <IconClose width={'26px'} />
                    </Link>

                    {doc.data.techstack_group.length > 0 && (
                        <Tags items={doc.data.techstack_group} />
                    )}

                    <PrismicRichText 
                        field={doc.data.content} 
                        components={{
                            image: (props) => {
                                return (
                                    <figure className="block-img">
                                        <img src={props.node.url} alt={props.node.alt || 'picture'} />
                                        {props.node.alt && (
                                            <figcaption>{props.node.alt}</figcaption>
                                        )}
                                    </figure>
                                )
                            }
                        }}
                    />

                    {doc.data.customer_website_url.url && doc.data.customer_name && (
                        <div>
                            Neem eens een kijkje {doc.data.customer_name ? 'bij ' : ' '}
                            <a href={doc.data.customer_website_url.url} target="_blank" rel="noreferrer">
                                {doc.data.customer_name ? doc.data.customer_name : 'op de website'}
                            </a>
                        </div>
                    )}
                </>
            </motion.div>

            {employeeData && (
                <motion.div className={'storyCard storyContact'}>
                    <Contact employee={employeeData} storyInfo={doc} />
                </motion.div>
            )}

            <Footer />
        </motion.div>
    )
}