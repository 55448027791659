import { Footer } from 'components';
import {
  BrowserRouter, Route, Routes
} from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import { Home, Legal } from './pages';
import Preview from 'pages/Preview';

const App = () => {

  return (
    <ParallaxProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/story/:id' element={<Home />} />
          <Route path='/proposition/:id' element={<Home />} />
          <Route path='/legal/:id' element={<Legal />} />
          <Route path='/preview' element={<Preview />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      <script async defer src="https://static.cdn.prismic.io/prismic.js?new=true&repo=pokkawebsite"></script>
    </ParallaxProvider>
  )
}

export default App;