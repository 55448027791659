import React, { useEffect, useState } from 'react'

import { useAllPrismicDocumentsByType } from "@prismicio/react";

import { LayoutGroup } from "framer-motion"

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { Header, Story, Stories, Bubbles, BackgroundBubblesContainer } from 'components';
import { ReactComponent as IconArrowRight } from '../icons/arrow-forward-outline.svg';
import { Proposition, Propositions } from 'components/proposition';
import { DocTypes } from './DocTypes.enums';

export const Home = () => {
    const [propositions, propositionsProps] = useAllPrismicDocumentsByType('proposition')
    const [projects, projectsProps] = useAllPrismicDocumentsByType('project')
    const type = window.location.pathname.split('/') && window.location.pathname.split('/')[1]
    const id = window.location.pathname.split('/') && window.location.pathname.split('/')[2]

    const [targetElement, setTargetElement] = useState<any>(null)

    const findPokkaStory = () => '/story/' + projects?.find(item => item.uid === 'pokka')!.slugs[0];

    const renderDoc = () => {
        switch (type) {
            case DocTypes.PROPOSITION:
                return (
                    <Proposition id={id} docs={propositions} onCloseStory={() => {
                        targetElement && enableBodyScroll(targetElement)
                        setTargetElement(null)
                    }} key={"selectedProposition"} />
                )
            case DocTypes.STORY:
                return (
                    <Story id={id} docs={projects} onCloseStory={() => {
                        targetElement && enableBodyScroll(targetElement)
                        setTargetElement(null)
                    }} key={"selectedStory"} />
                )
            default:
                return null;
        }
    }

    useEffect(() => {
        if (targetElement) {
            disableBodyScroll(targetElement)
            if (window.location.pathname === '/') {
                enableBodyScroll(targetElement)
            }
        }

    }, [id, targetElement])

    return (
        <>
            {propositionsProps.state === 'loaded' && propositionsProps.state === 'loaded' && (
                <LayoutGroup>
                    <Header />
                    <main style={{ backgroundColor: 'white', position: 'relative', zIndex: 2 }}>

                        <Propositions docs={propositions} onShowStory={() => {
                            setTimeout(() => {
                                setTargetElement(document.querySelector('div[data-proposition-opened]')!)
                            }, 200)
                        }} selectedId={id} />

                        <Stories docs={projects} onShowStory={() => {
                            setTimeout(() => {
                                setTargetElement(document.querySelector('div[data-story-opened]')!)
                            }, 200)
                        }} selectedId={id} />

                        {id && projects && propositions && renderDoc()}

                        <BackgroundBubblesContainer>
                            <Bubbles isLarge maximum={5} minimum={4} />
                        </BackgroundBubblesContainer>
                    </main>

                    {projects && findPokkaStory() && (
                        <a href={findPokkaStory()} className='hiring'>
                            #Hiring
                            <IconArrowRight width={'20px'} style={{ marginLeft: '.5rem' }} />
                        </a>
                    )}
                </LayoutGroup>
            )}
        </>
    )
}