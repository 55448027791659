import * as EmailJS from '@emailjs/browser';
import { PrismicText } from '@prismicio/react';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';

import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from '..';

import { ReactComponent as EmployeeBackground } from '../shapes/SVG/employee_title-background.svg';
import { ReactComponent as IconArrowRight } from '../../icons/arrow-forward-outline.svg';

import './contact.scss';

type ContactProps = {
    employee?: any;
    storyInfo?: any;
    traject?: number;
}

export const ContactLabs = ({ employee, storyInfo, traject }: ContactProps) => {
    const [captchaValidated, setCaptchaValidated] = useState(false)
    const [disabledForm, setDisabledForm] = useState(true)
    const [formFilled, setFormFilled] = useState({
        from_name: false,
        from_email: false,
        subject: false,
    })
    const [hideConfetti, setHideConfetti] = useState(false)
    const [loading, setLoading] = useState(false)
    const [messageSend, setMessageSend] = useState(false)

    EmailJS.init(process.env.REACT_APP_EMAILJS_API_PUBLIC_KEY!)

    const sendMail = (event: SyntheticEvent | any) => {
        event.preventDefault()

        setLoading(true)

        let data = {
            to_name: 'Pokka bro',
            employee: `${employee.data.firstname[0].text} ${employee.data.lastname[0].text}`,
            story_title: storyInfo.data.title[0].text,
            story_url: window.location.href,
        }

        Array.from(event.target.elements).forEach((item: any) => {
            if (item.id) {
                //@ts-ignore
                data[item.id] = item.value
            }
        })

        console.log('data', data)

        EmailJS.send(process.env.REACT_APP_EMAILJS_SERVICE_ID_OUTLOOK!, process.env.REACT_APP_EMAILJS_TEMPLATE_ID_LABS!, data, process.env.REACT_APP_EMAILJS_API_PUBLIC_KEY)
            .then(response => {
                console.log('response', response)
                setLoading(false)
                if (response.status === 200) {
                    setMessageSend(true)
                }
            })
    }

    const captchaCheck = (event) => {
        console.log(event.target.value)
        let user_captcha_value = event.target.value;

        if (validateCaptcha(user_captcha_value) === true) {
            console.log('Captcha Matched');
            setCaptchaValidated(true)
        } else {
            console.log('Captcha Does Not Match');
        }
    };

    const onValidateForm = (event) => {
        const id = event.target.id
        const value = event.target.value
        console.log('value', value)
        setFormFilled(prevState => {
            return {
                ...prevState,
                [id]: value ? true : false
            }
        })
    }

    useEffect(() => {
        !captchaValidated && loadCaptchaEnginge(6)
    }, [captchaValidated])


    useEffect(() => {
        let notFilledForm = false
        for (const key in formFilled) {
            !formFilled[key] && (notFilledForm = true)
        }

        captchaValidated && setDisabledForm(notFilledForm)
    }, [formFilled, captchaValidated])

    useEffect(() => {
        if (messageSend) { 
            setTimeout(() => {setHideConfetti(true)}, 10000)
        }
    }, [messageSend])

    useEffect(() => {
        traject && setFormFilled(prevState => {
            return {
                ...prevState,
                subject: true
            }
        })
    }, [traject])

    return (
        <div className={'container'}>

            <div className={'contactContainer'} style={{ backgroundImage: `url("${employee.data.photo.url}")` }}>

                <div className='employeeData'>
                    <h2>
                        <PrismicText field={employee.data.firstname} />
                        {' '}
                        <PrismicText field={employee.data.lastname} />
                    </h2>
                    <div>
                        {employee.data.position}
                    </div>
                    <div>
                        <a href={`tel:${employee.data.phonenumber[0].text}`}><PrismicText field={employee.data.phonenumber} /></a>
                    </div>
                </div>
                <EmployeeBackground />
            </div>
            {messageSend ? (
                <>
                    <div className={"formContainer"} style={{alignItems: 'center', justifyContent: 'center', minHeight: '40rem', textAlign: 'center'}}>
                        <span style={{fontSize: '6rem'}}>👍</span>
                        <br/>
                        <br/>
                        YEAH, je verzoek is binnen!
                        <br/>
                        <br/>
                        Je hoort snel van ons!
                    </div>
                    {!hideConfetti && (
                        <ReactConfetti />
                    )}
                </>
            ) : (
                <form className={"formContainer"} onSubmit={(event) => sendMail(event)} style={{opacity: loading ? .5 : 1}}>
                    <div className='inputGroup'>
                        <input id={'from_name'} type={'text'} html-for={'labelname'} onBlur={onValidateForm} />
                        <label id={'labelname'}>Naam <span>*</span></label>
                    </div>
                    <div className='inputGroup'>
                        <input id={'from_email'} type={'email'} html-for={'labelemail'} onBlur={onValidateForm} />
                        <label id={'labelemail'}>Email <span>*</span></label>
                    </div>
                    <div className='inputGroup'>
                        <input id={'from_phone'} type={'number'} html-for={'labelphone'} />
                        <label id={'labelphone'}>Telefoon</label>
                    </div>
                    <div className='inputGroup'>
                        <select id={'subject'} disabled html-for={'labelsubject'} value={traject ? traject.toString() : 'Maak een keuze'} onChange={onValidateForm}>
                            <option disabled value={'true'} id={'1'}>Maak een keuze</option>
                            <option id={'2'}>Visueel concept</option>
                            <option id={'3'}>Klikbaar prototype</option>
                            <option id={'4'}>Versie 1.0</option>
                            <option id={'5'}>Volledig product</option>
                        </select>
                            <label id='labelsubject'>Brochure voor (maak keuze hierboven) <span>*</span></label>
                    </div>
                    <div className='inputGroup'>
                        <textarea id='message' rows={8} html-for='labelmessage' onBlur={onValidateForm}></textarea>
                        <label id='labelmessage'>Vragen?</label>
                    </div>

                    {!captchaValidated ? (
                        <div className='inputGroup'>
                            <input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" onBlur={captchaCheck} />
                            <LoadCanvasTemplate />
                            <label>Captcha <span>*</span></label>
                        </div>
                    ) : (
                        <div className='inputGroup'>
                            Correcte captcha! Niet altijd even makkelijk..
                        </div>
                    )}
                    <div className='inputGroup'>
                        <button disabled={disabledForm} data-has-icon={'true'} type={'submit'}>
                            Verzend
                            <IconArrowRight width={'24px'} />
                        </button>
                    </div>
                </form>
            )}

        </div>
    )
}