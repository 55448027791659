import { PropsWithChildren, useEffect, useState } from "react"
import { isChrome, isChromium } from "react-device-detect"
import { Parallax } from "react-scroll-parallax"

export const BackgroundBubblesContainer = ({children}: PropsWithChildren) => {
    const [scrollingActive, setScrollingActive] = useState(false)
    
    useEffect(() => {
        const windowHeight = window.innerHeight

        const scrollHandler = () => {
            if (windowHeight <= window.scrollY) {
                setScrollingActive(true)
            } else {
                setScrollingActive(false)
            }
        }

        const scrollEventHandler = () => {
            window.addEventListener('scroll', scrollHandler, false)
        }

        return scrollEventHandler
    })

    return (
        <div className="storiesBackgroundBubbles" style={{ opacity: scrollingActive ? (isChromium || isChrome) ? .2 : .05 : 0, position: 'fixed', transform: scrollingActive ? 'scale(1)' : 'scale(6)' }}>
            <Parallax speed={-10}>
                {children}
            </Parallax>
        </div>
    )
}