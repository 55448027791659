import React, { useState } from 'react'

import { useAllPrismicDocumentsByType } from "@prismicio/react";

import { DocTypes } from './DocTypes.enums';
import { CleanContent } from 'components';

export const Legal = () => {
    const [legals, legalsProps] = useAllPrismicDocumentsByType('legal')
    const type = window.location.pathname.split('/') && window.location.pathname.split('/')[1]
    const id = window.location.pathname.split('/') && window.location.pathname.split('/')[2]

    const renderDoc = () => {
        switch (type) {
            case DocTypes.LEGAL:
                console.log('test', type, id, legals)
                return (
                    // <CleanPage id={id} docs={legals} />
                    <CleanContent data={legals?.find(legal => legal.uid === id)} />
                )
            default:
                return null;
        }
    }

    return (
        <>
            {legalsProps.state === 'loaded' && legalsProps.state === 'loaded' && renderDoc()}
        </>
    )
}