import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import reportWebVitals from './reportWebVitals';

import { PrismicProvider, PrismicToolbar } from '@prismicio/react'
import { client, repositoryName } from './prismic'

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <PrismicProvider client={client}>
      <App />
      {repositoryName && <PrismicToolbar repositoryName={repositoryName} />}
    </PrismicProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();