import moment from 'moment';
import { Bubbles, Logo } from "../";

import './footer.scss';

export const Footer = () => (
    <footer>
        <div className='storyBubblesContainer'>
            <Bubbles minimum={2} maximum={3} />
        </div>
       

        <strong>All rights reserved by</strong>
        <Logo width={'10rem'} />
        <div>© 2020 - {moment().format('YYYY')}</div>
        <div>
            <a href={'/legal/algemene-voorwaarden'}>Algemene voorwaarden</a>
        </div>
    </footer>
)